import * as React from "react";
import "./tarif-model.css";
import Layout from "../layouts/layout";
import parse from 'html-react-parser';
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";


const TarifPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { uri } = props.pageContext;
    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
            </Helmet>
            <div className="container-fluid service pt-5 pb-5">
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        {title}
                    </h1>
                </div>
            </div>


            <div className="container-fluid">
                <div className="container ">
                    <div className="row">
                        {content && parse(content)}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default TarifPage
